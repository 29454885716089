.loading-dots {
  font-size: 24px;
  text-align: center;
}

.loading-dots span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  background-color: #000;
  border-radius: 50%;
  animation: loading-dots-animation 1.4s infinite ease-in-out;
}

.loading-dots-white {
  text-align: center;
}

.loading-dots-white span {
  display: inline-block;
  width: 5px;
  height: 2px;
  margin: 0 4px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: loading-dots-animation 1.4s infinite ease-in-out;
}

.hide-hamburger-menu {
  position: fixed;
  top: 0;
  left: 230px;
  background-color: #fff;
  padding: 15px 20px; /* Adjust padding to increase height */
  color: white;
  height: 60px; /* Set line-height to 1 to center vertically */
  width: 1000px;
}

ol {
  margin: 20px 0;
  padding-left: 40px;
  list-style-type: decimal; /* Ensure numbers are shown */
}

ol li {
  margin-bottom: 10px;
}
@keyframes loading-dots-animation {
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
